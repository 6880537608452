import React from 'react';
import { Section, GradientCard } from 'components';
import * as styles from './styles.module.scss';

const LinkBlocksWithIcon = ({ data }) => {
  const {
    primary: { title1: title },
    items,
  } = data;

  return (
    <Section>
      <h3>{title.text}</h3>
      <div className={`wtf ${styles.cardContainer}`}>
        {items.map((block) => {
          const { icon, block_url: blockUrl, block_title: blockTitle, block_description: blockDescription } = block;

          return (
            <GradientCard cardClass={styles.card} linkTo={blockUrl.url} key={blockTitle.text}>
              <img src={icon.url} alt={`${blockTitle.text} icon`} />
              <h5>{blockTitle.text}</h5>
              <div className={styles.descContainer} dangerouslySetInnerHTML={{ __html: blockDescription.html }} />
            </GradientCard>
          );
        })}
      </div>
    </Section>
  );
};

export default LinkBlocksWithIcon;
