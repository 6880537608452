// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--8d655";
export var animateScrollOut = "styles-module--animateScrollOut--9d717";
export var card = "styles-module--card--c4cb0";
export var cardContainer = "styles-module--cardContainer--a4219";
export var colorSequence = "styles-module--colorSequence--cf7b7";
export var descContainer = "styles-module--descContainer--7c686";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--f3383";
export var grow = "styles-module--grow--7f8d2";
export var growAndShrink = "styles-module--growAndShrink--e9111";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--0f2dd";
export var moveBg = "styles-module--move-bg--845f0";
export var rotateLoop = "styles-module--rotateLoop--cf1e6";
export var spin = "styles-module--spin--b0475";
export var spinCounter = "styles-module--spinCounter--cc7bf";